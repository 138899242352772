<template>
  <div class="page">
    <HomeHero />
    <div class="section container has-text-centered">
      <h1 class="title is-size-1">
        Become an Expert!
      </h1>
      <p class="subtitle">
        Show your industry what you know.
      </p>
    </div>
    <div class="container content">
      <p>Experts are selected based on their industry expertise and knowledge.</p>
      <p>
        Write articles showing your experience and
        establishing you as a trusted voice in your industry.
      </p>
      <p>
        Interested? Check out our expert criteria and fill
        out the form below and we'll get in touch with you.
      </p>
    </div>
    <div v-show="expertCriteria.length > 0" class="section container content">
      <h2 class="has-text-centered">
        Expert Criteria
      </h2>
      <ol>
        <li v-for="(criteria, index) in expertCriteria" :key="index" v-html="criteria" />
      </ol>
    </div>
    <div v-show="!submitted" id="expertForm" class="section container">
      <form class="form" @submit.prevent="handleSubmit">
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <p class="control is-expanded has-icons-left">
                <input
                  v-model="user.email"
                  type="email"
                  class="input"
                  placeholder="Your Email"
                  required
                >
                <span class="icon is-small is-left has-text-grey">
                  <fa icon="envelope" />
                </span>
              </p>
            </div>
            <div class="field">
              <p class="control is-expanded has-icons-left">
                <input
                  v-model="user.name"
                  type="text"
                  class="input"
                  placeholder="Your Name"
                  required
                >
                <span class="icon is-small is-left has-text-grey">
                  <fa icon="user" />
                </span>
              </p>
            </div>
            <button class="button is-success" type="submit">
              Become an Expert
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-show="submitted" id="expertFormSubmitted" class="section container">
      <div class="notification is-primary has-text-centered">
        <p>Thank you for your interest!</p>
        <p>Someone will be in contact with you shortly.</p>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHero from '@/components/Global/HomeHero.vue';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      submitted: false,
      user: {
        email: '',
        name: '',
      },
    };
  },
  computed: {
    ...mapState({
      expertCriteria: (state) => state.expert.criteria ?? [],
    }),
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
    },
  },
  components: {
    HomeHero,
  },
};
</script>
